@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/* Input  Container */
#autoaddress-container {
  height: auto;
  flex-wrap: nowrap;
  font-family:
    Open Sans,
    sans-serif;
  font-size: 16px;
  position: relative;
  text-align: left;
}

.autoaddress-hidden {
  display: none;
}

#autoaddress-input {
  border: 0px solid #cccccc;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  padding: 8px 4px 8px 12px;
  margin-bottom: 0px;
  box-shadow: -1px 1px 7px -1px #cccccc;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  text-overflow: ellipsis;
}

#autoaddress-input:focus {
  outline: 0;
}

#autoaddress-input::placeholder {
  color: #8a8a8a;
}

#autoaddress-input:hover {
  box-shadow: -1px 1px 7px -1px #bbbbbb;
}

#autoaddress-input.display-list {
  border-bottom-left-radius: 0px;
}

#autoaddress-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  padding: 9px 15px;
  background-color: #154881;
  font-size: 16px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 46px;
  box-shadow: 1px 1px 4px 0px #cccccc;
}

#autoaddress-button:hover {
  background-color: #154871;
  box-shadow: -1px 1px 7px -1px #bbbbbb;
}

#autoaddress-button.display-list {
  border-bottom-right-radius: 0px;
}

.autoaddress-filter {
  float: none;
  width: 100%;
  flex: 1;
  padding: 5px 10px 0px 5px;
}

#autoaddress-filter-input {
  border: 0px solid #cccccc;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  padding: 8px 4px 8px 12px;
  margin-bottom: 0px;
  box-shadow: -1px 1px 7px -1px #cccccc;
  border-radius: 4px;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  text-overflow: ellipsis;
}



#autoaddress-list-container {
  position: absolute;
  font-size: 14px;
  width: 100%;
  line-height: 20px;
  box-shadow: -1px 1px 7px -1px #cccccc;
  list-style-type: none;
  padding-inline-start: unset;
  margin-bottom: 0px;
  background-color: #ffffff;
  color: #696969;
  scrollbar-color: #7f7f7f #ffffff;
  scrollbar-width: thin;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  /* Ensure autocomplete options appear on top of content below */
  z-index: 999;
}

.autoaddress-has-filter>#autoaddress-list-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

#autoaddress-list {
  position: relative;
  width: 100%;
  margin-top: 0;
  line-height: 20px;
  max-height: 295px;
  overflow-y: auto;
  box-shadow: 0 0 0 1px #e0e0e0;
  list-style-type: none;
  padding-inline-start: unset;
  margin-bottom: 0px;
  background-color: #ffffff;
  color: #696969;
  scrollbar-color: #7f7f7f #ffffff;
  scrollbar-width: thin;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

::placeholder {
  color: #cccccc;
}

#autoaddress-list.div-form {
  position: relative;
  border-radius: 6px;
  /* max-width: 450px; */
  width: 100%;
}

.autoaddress-inline #autoaddress-list {
  margin-top: 1px;
}

#autoaddress-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

#autoaddress-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

#autoaddress-list li {
  display: flex;
  box-sizing: border-box;
  padding: 5px 0 5px 10px;
  border: solid 1px rgb(224, 214, 214);
  border-top: none;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.autoaddress-back {
  padding: 5px 0 5px 5px !important;
  cursor: pointer;
  background-color: #e7e6e3;
  flex-direction: column;
  align-items: flex-start !important;
}

/* Label */

#autoaddress-label {
  width: 100%;
  border: solid 1px #cccccc;
  border-radius: 3px;
}

#autoaddress-label-address-list {
  list-style: none;
  padding-inline-start: 10px;
  margin-bottom: 0;
}

#autoaddress-label-address-list li {
  margin: 10px 0 0 0;
}

#autoaddress-label-button {
  float: right;
  margin-right: 15px;
  margin-bottom: 10px;

  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #3898ec;
  text-decoration: underline;
  cursor: pointer;
}

.autoaddress-dropdown-item-text .bold-addressline {
  opacity: 1;
  font-family: 'Open Sans', sans-serif;
  color: #696969;
  font-weight: 600;
  font-size: 14px;
}

.autoaddress-dropdown-item-text .addressline {
  display: inline;
  color: rgba(19, 19, 19, 0.58);
  font-size: 13px;
}

.autoaddress-dropdown-item-text {
  flex: 1;
  margin-right: 5px;
}

#autoaddress-manual-enter {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  font-style: italic;
  background-color: #f5f4f3;
  font-family: 'Open Sans', sans-serif;
  color: #6d6d6d;
}

#autoaddress-list li:hover,
#autoaddress-list li.selected {
  background-color: #e6edf5;
}

li#autoaddress-empty-list {
  background: #f5f4f3;
}

li#autoaddress-empty-list:hover {
  background: #f5f4f3;
  cursor: default;
}

.autoaddress-item-list {
  margin-right: 7px;
}

.italic-text {
  margin-right: 0px;
  color: rgba(19, 19, 19, 0.58);
  font-size: 13px;
}

.autoaddress-dropdown-item-has-options img {
  float: right;
  height: 15;
  width: 10;
  max-height: 12px;
  max-width: 8px;
  vertical-align: middle;
  display: inline-block;
  opacity: 0.71;
  font-size: 12px;
  line-height: 12px;
  margin-right: 5px;
}

#autoaddress-button .magnifying-glass {
  width: 20px;
}

.enter-manual-label {
  display: inline-block;
  min-width: 70px;
  padding-right: 10px;
  padding-left: 2px;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.enter-manual-text {
  display: inline-block;
  width: 100%;
  height: 38px;
  padding-left: 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 0;
  box-shadow: 0 0 0 1px #e0e0e0;
}

.enter-manual-text.last {
  border: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.drp-down-select {
  display: block;
  width: 98%;
  height: 38px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #cccccc;
  padding-left: 12px;
  border: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.enter-manual-text:focus,
.drp-down-select:focus {
  border-color: #3898ec;
  outline: 0;
}

.div-form {
  max-height: fit-content !important;
  overflow-y: hidden !important;
}

.autoaddress-manual-entry-form {
  margin-bottom: 0px;
}

.autoaddress-row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
}

.autoaddress-col {
  width: 100%;
}

*[class*='autoaddress-col-'] {
  flex: 0 0 auto;
  width: 100%;
}

*[class*='autoaddress-col']>*:first-child {
  margin-left: 2px;
}

.autoaddress-col-1 {
  flex: 1;
}

.autoaddress-col-2 {
  flex: 2;
}

.autoaddress-col-3 {
  flex: 3;
}

.autoaddress-col-4 {
  flex: 4;
}

.autoaddress-col-5 {
  flex: 5;
}

.autoaddress-col-6 {
  flex: 6;
}

.autoaddress-col-7 {
  flex: 7;
}

.autoaddress-col-8 {
  flex: 8;
}

.autoaddress-col-9 {
  flex: 9;
}

.autoaddress-col-10 {
  flex: 10;
}

.autoaddress-col-11 {
  flex: 11;
}

.autoaddress-col-12 {
  width: 100%;
}

.autoaddress-input-control {
  height: 46px;
  width: 100%;
  display: flex;
}

.autoaddress-has-filter>.autoaddress-input-control {
  display: none;
}

.autoaddress-input-control::placeholder {
  color: #1a1a1a80;
}

.autoaddress-error-text {
  padding-left: 12px;
  color: rgb(255 0 0);
  margin-top: 10px;
  margin-bottom: 10px;
  display: none;
}

.autoaddress-error-text.autoaddress-show {
  display: block;
}

.material-theme .enter-manual-text-area.autoaddress-invalid {
  box-shadow: none;
  border: 0.5px solid rgb(225, 0, 0, 0.5);
}

.enter-manual-text-area.autoaddress-invalid {
  box-shadow: none;
  border: 0.5px solid rgb(225, 0, 0, 0.5);
}

.material-theme .enter-manual-text-area.autoaddress-incorrect {
  box-shadow: none;
  border: 0.5px solid rgb(225, 0, 0, 0.5);
}

#autoaddress-container.material-theme {
  /* theme class places on container */
  margin: 0px;
  width: 100%;
  max-width: none;
  margin-bottom: 5px;
}

#autoaddress-container.material-theme.autoaddress-inline {
  /* theme class places on container */
  margin-bottom: 0px;
}

.material-theme #autoaddress-list {
  box-shadow: none;
  background-color: transparent;
}

.material-theme .autoaddress-col {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.material-theme *[class*='autoaddress-col-'] {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.material-theme .floating-label {
  border-radius: 5px;
  position: absolute;
  height: max-content;
  top: -9px;
  left: 20px;
  display: inline-block;
}

.material-theme .top-floating-div {
  position: absolute;
  height: 50%;
  width: inherit;
  padding: 0px 4px;
  left: 0;
  right: 0;
  background-color: transparent;
}

.material-theme .bottom-floating-div {
  position: absolute;
  height: 50%;
  width: inherit;
  padding: 0px 4px;
  left: 0;
  right: 0;
  background-color: white;
  top: 8px;
}

.material-theme .enter-manual-label {
  position: relative;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #79747e;
  min-width: 0px;
  padding: 0px 4px;
  top: -3px;
}

.material-theme .drp-down-select {
  background-color: rgb(255, 255, 255);
  padding: 9px 40px 9px 16px;
  height: 56px;
  border: 1px solid #79747e !important;
  border-radius: 3px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1b1f;
  width: 100%;
}

.material-theme .enter-manual-text {
  box-sizing: border-box;
  box-shadow: none;
  padding: 10px 10px 10px 20px;
  background: #ffffff;
  border: 1px solid #79747e !important;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 56px;
  color: #1c1b1f;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.material-theme .enter-manual-text-area {
  box-sizing: border-box;
  box-shadow: none;
  padding: 15px 10px 10px 20px;
  background: #ffffff;
  border: 1px solid #79747e;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 56px;
  color: #1c1b1f;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  resize: none;
  overflow: hidden;
  outline: none;
}

.material-theme .arrow {
  margin: 0px 5px 0px 0px;
}

.spinner {
  width: 20px;
  /* Adjust as needed */
  height: 20px;
  /* Adjust as needed */
  border: 3px solid #fff;
  /* White border */
  border-top: 3px solid transparent;
  /* Transparent top border */
  border-radius: 50%;
  /* Border radius for rounded shape */
  animation: spin 1s linear infinite;
  /* Animation for rotation */
}

#autoaddress-button[data-loading='false'] .spinner {
  display: none;
}

#autoaddress-button[data-loading='true'] .spinner {
  display: block;
}

#autoaddress-button[data-loading='false'] .magnifying-glass {
  display: block;
}

#autoaddress-button[data-loading='true'] .magnifying-glass {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.autoaddress-size-sm .autoaddress-col-sm-1 {
  flex: 1;
}

.autoaddress-size-sm .autoaddress-col-sm-2 {
  flex: 2;
}

.autoaddress-size-sm .autoaddress-col-sm-3 {
  flex: 3;
}

.autoaddress-size-sm .autoaddress-col-sm-4 {
  flex: 4;
}

.autoaddress-size-sm .autoaddress-col-sm-5 {
  flex: 5;
}

.autoaddress-size-sm .autoaddress-col-sm-6 {
  flex: 6;
}

.autoaddress-size-sm .autoaddress-col-sm-7 {
  flex: 7;
}

.autoaddress-size-sm .autoaddress-col-sm-8 {
  flex: 8;
}

.autoaddress-size-sm .autoaddress-col-sm-9 {
  flex: 9;
}

.autoaddress-size-sm .autoaddress-col-sm-10 {
  flex: 10;
}

.autoaddress-size-sm .autoaddress-col-sm-11 {
  flex: 11;
}

.autoaddress-size-sm .autoaddress-col-sm-12 {
  width: 100%;
}

.autoaddress-size-md .autoaddress-col-md-1 {
  flex: 1;
}

.autoaddress-size-md .autoaddress-col-md-2 {
  flex: 2;
}

.autoaddress-size-md .autoaddress-col-md-3 {
  flex: 3;
}

.autoaddress-size-md .autoaddress-col-md-4 {
  flex: 4;
}

.autoaddress-size-md .autoaddress-col-md-5 {
  flex: 5;
}

.autoaddress-size-md .autoaddress-col-md-6 {
  flex: 6;
}

.autoaddress-size-md .autoaddress-col-md-7 {
  flex: 7;
}

.autoaddress-size-md .autoaddress-col-md-8 {
  flex: 8;
}

.autoaddress-size-md .autoaddress-col-md-9 {
  flex: 9;
}

.autoaddress-size-md .autoaddress-col-md-10 {
  flex: 10;
}

.autoaddress-size-md .autoaddress-col-md-11 {
  flex: 11;
}

.autoaddress-size-md .autoaddress-col-md-12 {
  width: 100%;
}

.autoaddress-size-lg .autoaddress-col-lg-1 {
  flex: 1;
}

.autoaddress-size-lg .autoaddress-col-lg-2 {
  flex: 2;
}

.autoaddress-size-lg .autoaddress-col-lg-3 {
  flex: 3;
}

.autoaddress-size-lg .autoaddress-col-lg-4 {
  flex: 4;
}

.autoaddress-size-lg .autoaddress-col-lg-5 {
  flex: 5;
}

.autoaddress-size-lg .autoaddress-col-lg-6 {
  flex: 6;
}

.autoaddress-size-lg .autoaddress-col-lg-7 {
  flex: 7;
}

.autoaddress-size-lg .autoaddress-col-lg-8 {
  flex: 8;
}

.autoaddress-size-lg .autoaddress-col-lg-9 {
  flex: 9;
}

.autoaddress-size-lg .autoaddress-col-lg-10 {
  flex: 10;
}

.autoaddress-size-lg .autoaddress-col-lg-11 {
  flex: 11;
}

.autoaddress-size-lg .autoaddress-col-lg-12 {
  width: 100%;
}

@media screen and (max-width: 479px) {
  #autoaddress-container {
    width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  .autoaddress-item-list {
    display: none;
  }

  .magnifying-glass {
    display: block;
    margin: auto;
  }

  /* *[class*="autoaddress-col-"]{
        width: 100% !important;
    } */
}